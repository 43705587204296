/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function() {
	
    // Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
	jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen flex-video'/>");
		} else {
		  jQuery(this).wrap("<div class='flex-video'/>");
		}
	});
});

/*
Oleva custom functions
*/

function stickyScroll(){
	jQuery("[data-menu-underline-from-center] a").addClass("underline-from-center");
	/* Other pages for window ready (works)*/
	var navStick = jQuery("#desktop-nav-position").position();
	var navHeight = jQuery("nav.desktop-nav").height();

	jQuery(window).scroll(function() {
		var winTop = jQuery(window).scrollTop();
		/* Other pages */
		if (winTop >= navStick.top) {
			jQuery("nav.desktop-nav").addClass("desktop-nav-stick");
			jQuery("#inner-content").css('margin-top', navHeight);
		} else {
			jQuery("nav.desktop-nav").removeClass("desktop-nav-stick");
			jQuery("#inner-content").css('margin-top', '0');
		}
	});
};

function mobileMenu(){
	// MOBILE MENU
	jQuery('[data-curtain-menu-button]').click(function(){
	  jQuery('body').toggleClass('curtain-menu-open');
	})
};

function initLayout(){
    var contentheight = jQuery(window).height(); - 150;
	jQuery("#content").css('min-height', contentheight);
};


jQuery(".scroll-guide").click(function() {
  jQuery(window).scrollTop(150);
});

jQuery(document).ready(function($) {

	/* Layout 
	initLayout();*/

	/* Mobile */
	mobileMenu();


		/* Other pages for document ready */
		stickyScroll();

});

jQuery(window).load(function() {

		/* Other pages for document ready */
		stickyScroll();

});

jQuery(window).resize(function() {
	stickyScroll();
});